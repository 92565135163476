body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcf8e4;
  font-family: 'Raleway', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}

:root{
  --translucent:rgba(0,0,0,0.1);
  --translucentHard:rgba(0,0,0,0.3);
  --color:#111;
  --bgColor:#fff;
}

* {
  box-sizing:border-box;
}